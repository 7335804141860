import classNames from 'classnames';
import styles from './tabs.module.scss';
import { Fragment, ReactNode, useState } from 'react';
import lodash from 'lodash';

/* eslint-disable-next-line */
export interface TabsProps<D> {
  type: 1 | 2;
  shortWord?: boolean;
  activeValue: D;
  options: Array<{
    label: string;
    value: D;
    inner_value?: string;
    description?: string;
  }>,
  fullWidth?: boolean;
  onTabChangeHandler: (newValue: D) => Promise<boolean>;
  activeRadioValue?: D;
  onClickRadioBoxHandler?: (newValue: D) => Promise<boolean>;
}

export function Tabs(props: TabsProps<string>) {
  const [loading, setLoading] = useState<boolean>(false);
  const onSwitchChangeHandler = lodash.debounce((newTab: string) => {
    setLoading(true);
    props.onTabChangeHandler(newTab).then((value) => {
      setLoading(false);
    });
  }, 100);
  return (
    <div className={classNames(
      styles['container'],
      'w-full h-[60px]',
      'flex items-center gap-0',
      // 'border-b border-solid border-primary-dark-grey',
      'overflow-y-hidden overflow-x-auto',
      'relative'
    )}>
      {props.options.map((option, id) => (
        <Fragment key={id}>
          <button title={option.description} className={classNames(
            'h-full flex justify-center items-center gap-4 px-1 md:px-2',
            {
              'min-w-0 md:min-w-[160px]': !props.fullWidth,
              'grow shrink': props.fullWidth
            },
            {
              'border-b-2 border-solid border-primary-golden -mb-[1px]': props.activeValue === option.value && props.type === 1,
              'border-l border-t border-r rounded-t-md border-solid border-t-primary-golden border-l-primary-golden border-r-primary-golden -mb-[1px] border-b-2 border-black-dark': props.activeValue === option.value && props.type === 2,
              'overflow-hidden': props.activeValue !== option.value,
            },
            {
              'text-white': props.activeValue === option.value,
              'text-secondary-grey': props.activeValue !== option.value,
            },
            'whitespace-nowrap',
            'font-inter text-sm md:text-lg font-normal leading-[27px]',
            'z-10'
          )} disabled={loading} onClick={() => onSwitchChangeHandler(option.value)}>
            <div className={classNames({
              'block': !props.shortWord,
              'hidden md:block': props.shortWord
            })}>
              <span className='px-4 font-inter font-normal text-lg leading-7'>
                {option.label}
              </span>
              {(props.activeRadioValue && option.inner_value) && <div className='p-2' onClick={(e) => {
                e.stopPropagation();
                if (props.onClickRadioBoxHandler && option.inner_value) {
                  props.onClickRadioBoxHandler(option.inner_value)
                }
              }}>
                <input type="radio" checked={props.activeRadioValue === option.inner_value} />
              </div>}
            </div>
            <div className={classNames({
              'hidden': !props.shortWord,
              'block md:hidden': props.shortWord
            })}>
              <span className='px-4 font-inter font-normal text-lg leading-7'>
                {props.activeValue !== option.value ? option.label.slice(0, 1) : option.label}
              </span>
            </div>
          </button>
        </Fragment>
      ))}
      <div className={classNames(
        'absolute bottom-0 w-full border-b border-solid',
        {
          'border-primary-dark-grey': props.type === 1,
          'border-primary-golden': props.type === 2
        }
      )}></div>
    </div>
  );
}

export default Tabs;
