import { useEffect, useState } from "react";
import { IQuestion } from "../interfaces/question.interface";
import lodash from "lodash";
import classNames from "classnames";
import { attachTooltip } from "../utilities/attach-tooltip";
import { IcCheck1, IcClose2 } from "@clc-v2/icons";
import "./tooltip.scss";
import "./answer-panel.scss";
import { embedVideo } from "@clc-v2/utilities";
import { useListener } from 'react-bus';
import LevelInformation from "../../level-information/level-information";

const mastered = `${process.env.NX_DOMAIN}/public/imgs/mastered.png`;

export interface PropsAnswerPanel {
	device_type: "mobile" | "tablet" | "desktop";
	orientation: "portrait" | "landscape";
	frozen: boolean;
	index: number;
	question: IQuestion;
	dictionary: Record<string, string>;
	mastered: boolean;
	show_reward?: boolean;
	select_answer: (correct: boolean, reward: number) => void;
	next?: () => void;
}

const ANSWER_ID = ["A", "B", "C", "D", "E", "F"]

export const AnswerPanel = (props: PropsAnswerPanel) => {
	const [selected_answer, set_selected_answer] = useState<number>(-1);
	const [selected_answer_correct, set_selected_answer_correct] = useState<boolean>(false);
	const [show_reward, set_show_reward] = useState<boolean>(false);
	const [reward, set_reward] = useState<number>(0);
	const select_answer = lodash.debounce(props.select_answer, 200);
	const select_answer_handler = (correct: boolean, reward: number, id: number) => {
		if (selected_answer < 0) {
			set_selected_answer_correct(correct);
			set_selected_answer(id);
			select_answer(correct, reward);
		}
	}
	const show_reward_handler = (reward_point: any) => {
		if (typeof reward_point === 'number') {
			set_show_reward(true);
			set_reward(reward_point);
			setTimeout(() => set_show_reward(false), 5000);
		}
	}
	useListener('show-reward', show_reward_handler);
	useEffect(() => {
		set_selected_answer(-1);
		set_reward(0);
		set_selected_answer_correct(false);
	}, [props.question]);
	return (
		<div className={classNames(
			'relative',
			'w-full'
		)}>
			<div className={classNames(
				'absolute z-30',
				{
					'-left-10 top-0': props.device_type !== 'mobile',
					'top-4 right-4': props.device_type === "mobile"
				}
			)}>
				<LevelInformation />
			</div>
			<div className={classNames(
				'p-6 md:rounded-2xl gradient-account-panel from-color-3 to-secondary-coal thin-scrollbar backdrop-blur rounded-md relative',
				{
					'h-fit': props.orientation === 'portrait',
					'h-[375px] lg:min-h-[375px] lg:h-[488px] lg:max-h-[528px] mx-auto': !(props.device_type === 'mobile' && props.orientation === 'landscape'),
					'h-[calc(100vh-100px)]': props.device_type === 'mobile' && props.orientation === 'landscape'
				},
				'overflow-y-auto'
			)}>
				{(props.mastered) && (
					<img className="absolute top-0 right-0" src={mastered} alt='Mastered' />
				)}
				{
					props.question.hand_number > 0 ? (
						<>
							<div className="flex items-center gap-4">
								<span className={classNames(
									"font-semibold text-text-golden font-inter",
									{
										"text-base md:text-2xl": props.orientation === 'portrait',
										"text-sm lg:text-2xl": props.orientation === 'landscape'
									}
								)}>Hand {props.question.hand_number}</span>
								{props.show_reward && show_reward && reward !== 0 && (
									<span className={classNames(
										"font-bol",
										{
											"text-green": selected_answer_correct && reward >= 0,
											"text-primary-golden": !selected_answer_correct && reward >= 0,
											"text-red-500": reward < 0
										},
										// {
										// 	"text-sm": orientation === 'portrait',
										// 	"text-sm": orientation === 'landscape'
										// },
										"text-sm",
										"animate-pulse"
									)}>{reward >= 0 ? '+' : ''}{reward} XP</span>
								)}
							</div>
							{/* <p className="text-2xl font-medium text-white">Question #{props.index}</p> */}
							<div className={classNames(
								"font-light text-[rgba(240,240,240,1)] question",
								{
									"text-sm md:text-base mt-2 md:mt-4 mb-2 md:mb-6": props.orientation === 'portrait',
									"text-xs lg:text-base mt-1 md:mt-2 lg:mt-4 mb-1 md:mb-2 lg:mb-6": props.orientation === 'landscape'
								}
							)} dangerouslySetInnerHTML={{ __html: props.question.description }} />
							<div className="flex flex-col gap-2 mt-4 relative">
								{props.frozen && <div className="absolute left-0 top-0 w-full h-full bg-black bg-opacity-20"></div>}
								{props.question.answers.map((answer, id) => (
									<button
										className={classNames(
											"w-full",
											"grow-0 shrink-0",
											"flex justify-start items-center",
											"rounded-md",
											{
												"h-8 md:h-12": props.orientation === 'portrait',
												"h-6 md:h-8 lg:h-12": props.orientation === 'landscape',
												"px-2 md:px-4": props.orientation === 'portrait',
												"text-sm md:text-base": props.orientation === 'portrait',
												"px-1 md:px-2 lg:px-4": props.orientation === 'landscape',
												"text-xs md:text-sm lg:text-base": props.orientation === 'landscape',
											},
											{
												"bg-white-grey hover:bg-primary-golden text-black hover:text-white": selected_answer < 0,
												"bg-[rgba(177,172,172,1)] text-[rgba(32,31,31,1)]": selected_answer >= 0 && (id !== selected_answer),
												"bg-green text-white-grey": selected_answer >= 0 && answer.correct && answer.reward > 0,
												"bg-primary-golden text-white-grey": selected_answer === id && !answer.correct && answer.reward > 0,
												"bg-red-500 text-white": id === selected_answer && !answer.correct && answer.reward <= 0,

												// "bg-white-grey hover:bg-primary-golden text-[rgba(2,2,2,1)] hover:text-white": selected_answer < 0,
												// "bg-[rgba(177,172,172,1)] text-[rgba(32,31,31,1)]": selected_answer >= 0 && id !== selected_answer && !answer.correct,
												// "bg-[rgba(9,54,14,1)] border-[1.5px] border-[rgba(34,163,51,1)] text-white-grey": selected_answer >= 0 && answer.correct,
												// "bg-[rgba(245,9,9,0.27)] border-[1.5px] border-[rgba(255,4,21,1)] text-white": id === selected_answer && !answer.correct,
											},
										)}
										key={id}
										onClick={() => select_answer_handler(answer.correct, answer.reward, id)}
									>
										{ANSWER_ID[id]}. {answer.text}
									</button>
								))}
							</div>
							{
								selected_answer >= 0 && (
									<>
										<div className="w-full my-2 h-[1px] opacity-10" style={{
											background: "linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(184, 184, 184, 0.722221) 22.26%, #FFFFFF 50%, rgba(225, 225, 225, 0.882733) 79.73%, rgba(0, 0, 0, 0) 100%)",
										}} />
										<div className="mb-2 flex items-center gap-2">
											{selected_answer_correct ? <IcCheck1 color="rgba(117, 154, 71, 1)" /> : <IcClose2 size={24} color="rgba(239, 68, 68, 1)" />}
											<span className={classNames(
												"text-white font-semibold",
												{
													"text-base": props.orientation === 'portrait',
													"text-sm lg:text-base": props.orientation === 'landscape'
												}
											)}>Explanation</span>
										</div>

										{(props.question.video.link) && (
											<div className="mt-2 relative">
												<iframe title={props.question.video.link} width="100%" height="100%" style={{ backgroundColor: '#000' }} src={embedVideo(props.question.video.link)} allowFullScreen></iframe>
											</div>
										)}
										<div className={classNames(
											"mt-2 text-secondary-grey text-sm relative",
											{
												"text-sm": props.orientation === 'portrait',
												"text-xs lg:text-sm": props.orientation === 'landscape'
											}
										)} dangerouslySetInnerHTML={{ __html: attachTooltip(props.question.answers[selected_answer]?.explanation ?? '', props.dictionary) }} />
										{props.next && <div className="mt-4">
											<button className={classNames(
												"rounded-full w-full flex justify-center items-center bg-primary-golden",
												"font-inter font-medium text-black-dark-2 hover:text-white",
												{
													"h-10 text-base": props.orientation === 'portrait',
													"h-8 text-sm lg:h-10 lg:text-base": props.orientation === 'landscape'
												}
											)} onClick={() => {
												props.next?.();
												set_show_reward(false);
												set_reward(0);
											}}>Next Question</button>
										</div>}
									</>
								)
							}
						</>
					) : (
						<></>
					)
				}
			</div >
		</div>
	)
}