import { useEffect, useRef, useState } from 'react';
import { PlayerCard } from './components/player-card';
import { CURRENCY_MODE, Controller, PLAYING_STATUS } from './components/controller';
import styles from './poker-player.module.scss';
import classNames from 'classnames';
import { AnswerPanel } from './components/answer-panel';
import { Question } from '@prisma/client';
import { IHand, parseHandHistory } from './utilities/parse-hand-history';
import TableImg from './assets/images/table.png';
import { UTGLabeling } from './utilities/utg-labeling';
import { getPastPlayerIndex } from './utilities/get-past-player-index';
import { handleChipPos } from './utilities/handle-chip-pos';
import { changeLingo } from './utilities/change-lingo';
import { HouseOfCards } from './components/house-of-cards';
import { numberWithCommas } from './utilities/number-with-commas';
import { IData } from './interfaces/data.interface';
import { IAnswer } from './interfaces/answer.interface';
import { useMediaQuery } from "react-responsive";
import lodash from "lodash";

declare global {
  interface Window {
    opera?: any;
  }
}

let playing_interval: NodeJS.Timeout;

/* eslint-disable-next-line */
export interface PokerPlayerProps {
  // api_endpoint: string;
  // hand_number: number;
  device_type: "mobile" | "tablet" | "desktop";
  orientation: "portrait" | "landscape";
  index: number;
  data: Question;
  dictionary: Record<string, string>;
  mastered: boolean;
  hide_next_question_btn?: boolean;
  playing_card?: string;
  show_reward?: boolean;
  handle_answer_question?: (hand_number: number, correct: boolean) => void;
  handle_next_question?: () => void;
  handle_change_playing_card?: (_playing_card: string) => void;
}

export function PokerPlayer(props: PokerPlayerProps) {
  const [error, set_error] = useState<string>('');
  const [data, set_data] = useState<IData>();
  const [playing_status, set_playing_status] = useState<PLAYING_STATUS>("pause");
  const [finished, set_finished] = useState<boolean>(false);
  const [once_finished, set_once_finished] = useState<boolean>(false);
  const [playing_speed, set_playing_speed] = useState<number>(5);
  const [currency_mode, set_currency_mode] = useState<CURRENCY_MODE>("bb");
  let [hand_index, set_hand_index] = useState<number>(0);
  const [use_start_index, set_use_start_index] = useState<boolean>(true);
  const [table_action, set_table_action] = useState<string>('');
  const [change_amount, set_change_amount] = useState<boolean>(false);
  const [pot, set_pot] = useState<number>(0);
  const [animation_blocker, set_animation_blocker] = useState<number>(0);
  const [init_block_play_btn, set_init_block_play_btn] = useState<boolean>(true);
  const [scale, set_scale] = useState<number>(1);
  const [playing_card, set_playing_card] = useState<string>('');
  // const ref = useRef<HTMLElement>() as React.MutableRefObject<HTMLInputElement>;
  // const { events } = useDraggable(ref);
  const stop = () => {
    if (data) {
      set_playing_status("pause");
      clearInterval(playing_interval);
      set_table_action('');
    }
  }
  const reset = () => {
    if (data) {
      set_playing_status("pause");
      set_hand_index(0);
      set_pot(0);
      set_finished(false);
      clearInterval(playing_interval);
      set_use_start_index(true);
      set_init_block_play_btn(true);
      set_table_action('');
      setTimeout(() => calculate_all_ante(), 1000);
    }
  }
  const move = () => {
    if (data) {
      let next_index = hand_index + 1;
      let amount = next_index < data.hands.length ? data.hands[next_index].amount : 0;
      if (next_index < data.hands.length) {
        if (data.hands[next_index].tableAction !== '') {
          set_data((_data) => {
            if (_data) {
              for (let i = hand_index; i >= 0; i--) {
                if (_data.hands[i].action === 'folds' || _data.hands[i].action === 'fold') {
                  _data.hands[i].action = 'fold';
                } else {
                  _data.hands[i].action = '';
                }
                _data.hands[i].amount = 0;
              }
            }
            return _data;
          })
          amount = 0;
        } else if (data.hands[next_index].action === '') {
          set_data((_data) => {
            if (_data) {
              for (let i = hand_index; i >= 0; i--) {
                if (_data.hands[i].action === 'folds' || _data.hands[i].action === 'fold') {
                  _data.hands[i].action = 'fold';
                } else {
                  _data.hands[i].action = '';
                }
                _data.hands[i].amount = 0;
              }
            }
            return _data;
          })
          amount = 0;
        }
      }
      if (use_start_index) {
        set_use_start_index(false);
      }
      if (data.hands.length === next_index) {
        stop();
        set_finished(true);
        if (!once_finished) {
          set_once_finished(true);
        }
        return;
      }
      if (playing_status === "pause") return;
      if (next_index < data.hands.length) {
        if (data.hands[next_index].tableAction !== '') {
          set_pot((_pot) => _pot);
        } else {
          set_pot((_pot) => _pot + amount);
        }
        set_table_action(
          data.hands[next_index].tableAction
        );
        set_change_amount(false);
        set_hand_index(hand_index += 1);
      } else {
        stop();
      }
    }
  }
  const start = () => {
    set_playing_status("play");
    move();
    playing_interval = setInterval(move, 6000 / playing_speed);
    set_change_amount(false);
  };
  const calculate_all_ante = () => {
    if (data) {
      let amount = 0;
      if (!data.noHandHistory) {
        data.hands.forEach(
          (hand: any, index: number) => {
            if (
              hand.action === 'ante' ||
              hand.action === 'posts the ante' ||
              hand.action === 'posts ante'
            ) {
              set_pot((amount += hand.amount));
              set_hand_index(index);
              set_animation_blocker(index + 1);
            }
          }
        );
      }
    }
  };
  const prev_handler = () => {
    if (data) {
      set_playing_status("pause");
      set_finished(false);
      clearInterval(playing_interval);
      for (let i = 0; i <= hand_index; i++) {
        data.hands[i].action = data.hands[i].copyAction;
        data.hands[i].amount = data.hands[i].copyAmount;
      }
      if (animation_blocker < hand_index && hand_index > 0) {
        let prev_index = hand_index - 1;
        set_pot((_pot) => _pot - data.hands[hand_index].amount);
        set_change_amount(true);
        set_hand_index(prev_index);
        set_table_action(data.hands[prev_index].tableAction);
      }
    }
  }
  const play_handler = () => {
    set_playing_status((_prev) => {
      if (_prev === "play") {
        return "pause"
      } else {
        return "play"
      }
    })
  }
  const next_handler = () => {
    if (data) {
      let next_index = hand_index + 1;
      let amount = next_index < data.hands.length ? data.hands[next_index].amount : 0;
      if (next_index < data.hands.length) {
        if (data.hands[next_index].tableAction !== '') {
          set_data((_data) => {
            if (_data) {
              for (let i = hand_index; i >= 0; i--) {
                if (_data.hands[i].action === 'folds' || _data.hands[i].action === 'fold') {
                  _data.hands[i].action = 'fold';
                } else {
                  _data.hands[i].action = '';
                }
                _data.hands[i].amount = 0;
              }
            }
            return _data;
          });
          amount = 0;
        } else if (data.hands[next_index].action === '') {
          set_data((_data) => {
            if (_data) {
              for (let i = hand_index; i >= 0; i--) {
                if (_data.hands[i].action === 'folds' || _data.hands[i].action === 'fold') {
                  _data.hands[i].action = 'fold';
                } else {
                  _data.hands[i].action = '';
                }
                _data.hands[i].amount = 0;
              }
            }
            return _data;
          });
          amount = 0;
        }
      }
      if (use_start_index) {
        set_use_start_index(false);
        clearInterval(playing_interval);
        set_playing_status("pause");
      }
      if (data.hands.length === next_index) {
        stop();
        set_finished(true);
        if (!once_finished) {
          set_once_finished(true);
        }
        return;
      }
      if (next_index < data.hands.length) {
        if (data.hands[next_index].tableAction !== '') {
          set_pot((_pot) => _pot);
        } else {
          set_pot((_pot) => _pot + amount);
        }
        set_table_action(data.hands[next_index].tableAction);

        set_change_amount(false);
        set_hand_index(next_index);
        set_playing_status("pause");
      }
    }
  }
  const refresh_handler = () => {
    if (data) {
      set_change_amount(true);
      for (let i = 0; i < hand_index; i++) {
        data.hands[i].action = data.hands[i].copyAction;
        data.hands[i].amount = data.hands[i].copyAmount;
      }
      reset();
    }
  }
  const speed_change_handler = (_new_playing_speed: number) => {
    stop();
    set_playing_speed(_new_playing_speed);
  }
  const currency_mode_change_handler = () => {
    set_currency_mode((_prev) => {
      if (_prev === "dollar") {
        return "bb"
      } else if (_prev === "bb") {
        return "dollar"
      } else {
        return "dollar"
      }
    })
  }
  const calls_change = (amount: number, hands: IHand[]) => {
    let handPrev = hands[0].amount;
    for (let i = 0; i <= hand_index; i++) {
      if (hands[i]) {
        if (hands[i].amount > handPrev) {
          handPrev = hands[i].amount;
          amount = handPrev;
        }
      }
    }

    return amount;
  }
  const handle_next_question = () => {
    reset();
    props.handle_next_question?.()
  }
  const playing_card_change_handler = useRef(lodash.debounce((card_new: string, card_old?: string) => {
    if (card_new !== card_old) {
      props.handle_change_playing_card?.(card_new)
    }
  }, 1000)).current;
  const sm = useMediaQuery({ query: '(min-width: 460px)' });
  const md = useMediaQuery({ query: '(min-width: 768px)' });
  const lg = useMediaQuery({ query: '(min-width: 1024px)' });
  const xl = useMediaQuery({ query: '(min-width: 1280px)' });
  useEffect(() => {
    if (props.data) {
      const playable_data = {
        ...parseHandHistory(props.data.hand_history),
        question: {
          // questionID: res.data.id,
          // questionNumber: 1,
          reward: {
            chip: props.data.reward_chip,
            ticket: props.data.reward_ticket,
          },
          ai_reward: {
            add: props.data.additional_chip,
            subtract: props.data.subtract_chip,
          },
          description: props.data.question,
          hand_number: props.data.hand_number,
          answers: ([
            {
              correct: true,
              text: props.data.answer_correct,
              explanation: props.data.explanation_correct,
            },
            ...props.data.answer_wrong.map((answer_wrong) => ({
              correct: false,
              text: answer_wrong,
              explanation: props.data.explanation_wrong
            }))
          ] as IAnswer[]).sort(() => Math.random() - 0.5),
          video: {
            link: props.data.video_link,
            explanation: props.data.video_explanation
          }
        },
      };
      set_data(playable_data);
    }
  }, [props.data]);
  useEffect(() => {
    return () => {
      stop();
    }
  }, []);
  useEffect(() => {
    if (data) {
      if (playing_status === "pause") {
        stop();
      } else {
        set_use_start_index(false);
        start();
      }
    }
  }, [playing_status, data]);
  useEffect(() => {
    if (init_block_play_btn) {
      setTimeout(() => set_init_block_play_btn(false), 2000);
    }
  }, [init_block_play_btn]);
  useEffect(() => {
    if (data) {
      reset();
    }
  }, [data]);
  useEffect(() => {
    set_once_finished(false);
  }, [props.index, props.data]);
  useEffect(() => {
    // if (xl) {
    //   set_scale(1);
    // } else if (lg) {
    //   set_scale(0.9);
    // } else if (md) {
    //   set_scale(0.8);
    // } else if (sm) {
    //   set_scale(0.5);
    // } else {
    //   set_scale(0.6);
    // }
  }, [md, lg, xl]);
  useEffect(() => {
    set_playing_card(props.playing_card ?? 'v1')
  }, [])
  useEffect(() => {
    playing_card_change_handler(playing_card, props.playing_card);
  }, [playing_card, props.playing_card])
  return (
    <div
      className={classNames(
        styles['container'],
        'w-full flex gap-6 relative',
        {
          'flex-col lg:flex-row': props.orientation === 'portrait',
          'flex-row': props.orientation === 'landscape'
        }
      )}
    >
      <div className='absolute left-0 top-0 flex items-center gap-2 z-30'>
        <button className='w-6 h-6 bg-white flex justify-center items-center rounded-full' onClick={() => set_scale((_prev) => _prev * 0.9)}>
          <span>-</span>
        </button>
        <button className='w-6 h-6 bg-white flex justify-center items-center rounded-full' onClick={() => set_scale((_prev) => _prev * 1.1)}>
          <span>+</span>
        </button>
      </div>

      <div
        className={classNames(
          styles['player-container'],
          'w-full flex flex-col justify-between items-center overflow-visible relative'
        )}
      >
        <div className='hidden absolute right-0 top-0 items-center gap-2 z-30'>
          <select className='w-20 h-6' name="" id="" value={playing_card} onChange={(e) => set_playing_card(e.target.value)}>
            <option value="v1">Deck 1</option>
            <option value="v2">Deck 2</option>
          </select>
        </div>
        <div
          className='w-full h-fit thin-scrollbar'
          style={{ transform: `scale(${scale})` }}
        >
          <div className={classNames(
            'flex items-start',
            'w-full h-fit',
          )}>
            <div className={classNames(
              styles['board-container'],
              'w-fit h-fit mx-auto',
              'flex lg:justify-center',
              'py-10',
              'transform origin-center',
            )}>
              <img
                className={classNames(
                  styles['board-background'],
                  'px-0 md:px-10 lg:px-20 py-0 md:py-8 lg:py-16',
                  'w-full',
                  'object-contain'
                )}
                src={TableImg}
                alt="Table Background"
              />
              <div className='absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                <HouseOfCards
                  device_type={props.device_type}
                  orientation={props.orientation}
                  cards={data?.flop ?? []}
                  table_action={table_action}
                  hand_index={hand_index}
                  count_of_players={data?.players.length ?? 0}
                  playing_card={playing_card}
                />
                <div className={classNames(
                  'mx-auto w-fit text-center text-white bg-secondary-coal px-6 py-1',
                  {
                    "mt-1 md:mt-4 text-[8px] md:text-sm rounded-md": props.device_type !== 'mobile',
                    "mt-1 text-[8px] rounded-sm": props.device_type === 'mobile',
                  }
                )}>
                  <span>POT</span>
                  <span className='mx-1'>{numberWithCommas(currency_mode === 'dollar' ? pot : Number((pot / (data?.tableInfo?.bb ?? 1)).toFixed(2)))}</span>
                  <span className='text-primary-golden font-bold'>{currency_mode === 'dollar' ? '' : 'BB'}</span>
                  {currency_mode === 'dollar'
                    ? `(${numberWithCommas(
                      data?.tableInfo?.bb ?? 1
                    )} BB)`
                    : null}
                </div>
              </div>
              {(data && data.tableInfo && data.tableInfo.players > 0) ? data.players.map((player, id) => {
                return (
                  <div className={classNames(
                    styles[`gameP${player.number}`],
                    'absolute',
                    {
                      'w-16 md:w-24': props.device_type !== 'mobile',
                      'w-16': props.device_type === 'mobile'
                    }
                  )} key={id}>
                    <PlayerCard
                      device_type={props.device_type}
                      orientation={props.orientation}
                      position_label={UTGLabeling(data.players, data.tableInfo?.dealer ?? 0, id, data.tableInfo?.sb === undefined)}
                      count_of_players={data.tableInfo?.players ?? 0}
                      player={player.number}
                      me={player.number === data.hands[use_start_index ? id : hand_index]?.player ? (data.hands[use_start_index ? id : hand_index]?.cards?.length ?? 0) > 0 : false} // not sure how to calculate and why? seems use_start_index is always false
                      cards={
                        player.number === data.hands[use_start_index ? id : hand_index]?.player ? (
                          data.hands[use_start_index ? id : hand_index]?.cards
                        ) : (
                          data.hands[getPastPlayerIndex(
                            data.hands,
                            player.number,
                            use_start_index ? id : hand_index
                          )]?.cards
                        )
                      }
                      mp={player.initAmount}
                      chipPos={
                        player.number === data.hands[use_start_index ? id : hand_index]?.player ? (
                          handleChipPos(data.hands[
                            use_start_index ? id : hand_index
                          ]?.player)
                        ) : (
                          handleChipPos(
                            data.hands[
                              getPastPlayerIndex(
                                data.hands,
                                player.number,
                                use_start_index ? id : hand_index
                              )
                            ]?.player
                          )
                        )
                      }
                      turn={player.number === data.hands[use_start_index ? id : hand_index]?.player}
                      dealer={data.tableInfo?.dealer ?? 0}
                      action={
                        player.number === data.hands[use_start_index ? id : hand_index]?.player ? (
                          changeLingo(data.hands[use_start_index ? id : hand_index]?.action, player)
                        ) : (
                          changeLingo(
                            data.hands[
                              getPastPlayerIndex(
                                data.hands,
                                player.number,
                                use_start_index ? id : hand_index
                              )
                            ]?.action,
                            player
                          )
                        )
                      }
                      amount={
                        player.number === data.hands[use_start_index ? id : hand_index]?.player ? (
                          data.hands[use_start_index ? id : hand_index]?.amount
                        ) : (
                          data.hands[
                            getPastPlayerIndex(
                              data.hands,
                              player.number,
                              use_start_index ? id : hand_index
                            )
                          ]?.amount
                        )
                      }
                      question_history={data.hands.slice(0, hand_index)}
                      display_amount={
                        player.number === data.hands[use_start_index ? id : hand_index]?.player ? (
                          data.hands[use_start_index ? id : hand_index]?.displayAmount
                        ) : (
                          data.hands[
                            getPastPlayerIndex(
                              data.hands,
                              player.number,
                              use_start_index ? id : hand_index
                            )
                          ]?.displayAmount
                        )
                      }
                      pot={pot}
                      bb={data.tableInfo?.bb ?? 0}
                      currency_mode={currency_mode}
                      call_money={
                        calls_change(
                          player.number === data.hands[use_start_index ? id : hand_index]?.player ? (
                            data.hands[use_start_index ? id : hand_index]?.amount
                          ) : (
                            data.hands[
                              getPastPlayerIndex(
                                data.hands,
                                player.number,
                                use_start_index ? id : hand_index
                              )
                            ]?.amount
                          ),
                          data.hands
                        )
                      }
                      change_amount={change_amount}
                      playing_card={props.playing_card}
                    />
                  </div>
                )
              }) : null}
            </div>
          </div>
        </div>

        <div className={classNames(
          styles['controller-container'],
          'h-8 md:h-10 lg:h-12 w-full flex justify-center',
          {
            'mt-10 md:mt-6 lg:mt-4': props.orientation === 'portrait',
            'mt-2 lg:mt-4': props.orientation === 'landscape'
          }
        )}>
          <Controller
            init_block_play_btn={init_block_play_btn}
            finished={finished}
            playing_status={playing_status}
            playing_speed={playing_speed}
            currency_mode={currency_mode}
            prev_handler={prev_handler}
            play_handler={play_handler}
            next_handler={next_handler}
            refresh_handler={refresh_handler}
            speed_change_handler={speed_change_handler}
            currency_mode_change_handler={currency_mode_change_handler}
          />
        </div>
      </div>
      <div
        className={classNames(
          styles['answer-panel'],
          'relative',
          'grow-0 shrink-0',
          // 'w-full max-w-[280px] md:max-w-80 lg:max-w-80',
          {
            'w-full lg:w-[420px]': props.orientation === 'portrait',
            'w-[280px] lg:w-[420px]': props.orientation === 'landscape'
          },
          'mx-auto',
          // 'border',
          // '!h-[640px]'
        )}
      // style={{
      //   transform: `scale(${scale})`
      // }}
      >
        {
          data && (
            <AnswerPanel
              device_type={props.device_type}
              orientation={props.orientation}
              mastered={props.mastered}
              frozen={!once_finished}
              index={props.index}
              question={data?.question}
              dictionary={props.dictionary}
              select_answer={(correct: boolean) => {
                props.handle_answer_question?.(props.data.hand_number, correct);
              }}
              next={props.hide_next_question_btn ? undefined : handle_next_question}
              show_reward={props.show_reward}
            />
          )
        }
      </div>
    </div>
  );
}

export default PokerPlayer;
