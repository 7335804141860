import styles from './ic-double-left-1.module.scss';

/* eslint-disable-next-line */
export interface IcDoubleLeft1Props {
  size: number;
  color: string;
}

export function IcDoubleLeft1(props: IcDoubleLeft1Props) {
  return (
    <svg className={styles['container']} width={props.size} height={props.size / 29 * 31} viewBox="0 0 29 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.61062 15.8066C6.05268 15.4523 6.05268 14.6381 6.61062 14.2838L14.5654 9.23283C15.1659 8.85155 15.9508 9.28293 15.9508 9.99422L15.9508 20.0962C15.9508 20.8074 15.1659 21.2388 14.5654 20.8576L6.61062 15.8066Z" fill={props.color} />
      <path d="M13.3023 15.8066C12.7443 15.4523 12.7443 14.6381 13.3023 14.2838L21.2571 9.23283C21.8575 8.85155 22.6424 9.28293 22.6424 9.99422L22.6425 20.0962C22.6425 20.8074 21.8575 21.2388 21.2571 20.8576L13.3023 15.8066Z" fill={props.color} />
    </svg>
  );
}

IcDoubleLeft1.defaultProps = {
  size: 29,
  color: 'white',
}

export default IcDoubleLeft1;
