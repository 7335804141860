import classNames from 'classnames';
import styles from './progressbar.module.scss';
import lodash from 'lodash';

/* eslint-disable-next-line */
export interface ProgressbarProps {
  device_type: "mobile" | "tablet" | "desktop";
  orientation: "portrait" | "landscape";
  index: number;
  hand_numbers: number[];
  played_hand_numbers: Array<{ hand_number: number, correct: boolean, reward: number }>;
  on_question_click_handler: (id: number) => void;
  on_history_click_handler: () => void;
}

export function Progressbar(props: ProgressbarProps) {
  const on_question_click_handler = lodash.debounce((id: number) => {
    props.on_question_click_handler(id);
  }, 300);
  return (
    <div className={classNames(
      styles['container'],
      'w-full',
      {
        'h-12': !(props.device_type === 'mobile' && props.orientation === 'landscape'),
        'h-9': props.device_type === 'mobile' && props.orientation === 'landscape'
      },
      'flex flex-col md:flex-row justify-center items-center gap-0 md:gap-4',
      'bg-card-glow-fill',
      'px-4',
      'rounded-lg md:rounded-full'
    )}>
      <div className='w-full md:grow md:shrink flex justify-center items-center gap-1'>
        {props.hand_numbers.map((_hand_number, id) => {
          const looping_hand_number = props.played_hand_numbers.filter(({ hand_number }) => hand_number === _hand_number)?.[0]
          return (
            <div className={classNames(
              'grow shrink',
              'max-w-[60px]',
              'cursor-pointer',
              {
                'bg-primary-golden': props.index === id || (
                  props.index !== id &&
                  looping_hand_number?.correct === false && looping_hand_number.reward > 0
                ),
                'bg-green': (
                  looping_hand_number?.correct
                ),
                'bg-red-500': (
                  props.index !== id &&
                  looping_hand_number?.correct === false && looping_hand_number.reward <= 0
                ),
                'bg-secondary-grey': (
                  props.index !== id &&
                  looping_hand_number === undefined
                ),
              },
              {
                'h-1 hover:h-2': props.index !== id,
                'h-2': props.index === id,
              }
            )} onClick={() => {
              on_question_click_handler(id)
            }} key={id} title={`Hand Number: ${_hand_number}`} />
          )
        })}
      </div>
      <div className={classNames(
        'grow-0 shrink-0 flex items-center gap-4',
        {
          'w-full justify-between': props.device_type === 'mobile' && props.orientation === 'portrait',
        }
      )}>
        <div className='grow shrink'>
          <span className='text-secondary-grey' onClick={() => {
            const question_index_str = prompt(`Type question index between ${1} and ${props.hand_numbers.length}.`);
            if (question_index_str) {
              try {
                const question_index = parseInt(question_index_str);
                if (0 < question_index && question_index <= props.hand_numbers.length) {
                  on_question_click_handler(question_index - 1);
                } else {
                  alert('Question index is out of range.');
                }
              } catch (err) {
                alert(err);
              }
            }
          }}>
            {props.index + 1}/{props.hand_numbers.length} Questions
          </span>
        </div>
        <div className='grow-0 shrink-0'>
          <span className='text-primary-golden cursor-pointer underline underline-offset-2' onClick={props.on_history_click_handler}>
            History
          </span>
        </div>
      </div>
    </div>
  );
}

export default Progressbar;
