import styles from './ic-check-1.module.scss';

/* eslint-disable-next-line */
export interface IcCheck1Props {
  size: number;
  color: string;
}

export function IcCheck1(props: IcCheck1Props) {
  return (
    <svg className={styles['container']} width={props.size} height={props.size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill={props.color} />
      <path d="M9 11.75L11.4107 14.25L15.75 9.75" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M9 11.75L11.4107 14.25L15.75 9.75" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
}

IcCheck1.defaultProps = {
  size: 24,
  color: 'green'
}

export default IcCheck1;
