import styles from './ic-edit-1.module.scss';

/* eslint-disable-next-line */
export interface IcEdit1Props {
  size: number;
  color: string;
}

export function IcEdit1(props: IcEdit1Props) {
  return (
    <svg className={styles['container']} width={props.size} height={props.size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.5 10.5V8.25L12 3.75L14.25 1.5L16.5 3.75L14.25 6L9.75 10.5H7.5Z" fill={props.color} />
      <path d="M7.50015 3H5.40015C4.56007 3 4.13972 3 3.81885 3.16349C3.5366 3.3073 3.3073 3.5366 3.16349 3.81885C3 4.13972 3 4.56007 3 5.40015V12.6001C3 13.4402 3 13.86 3.16349 14.1809C3.3073 14.4632 3.5366 14.6929 3.81885 14.8367C4.1394 15 4.55925 15 5.39768 15H12.6023C13.4408 15 13.86 15 14.1805 14.8367C14.4628 14.6929 14.6929 14.4629 14.8367 14.1807C15 13.8601 15 13.4408 15 12.6023V10.5M12 3.75L7.5 8.25V10.5H9.75L14.25 6M12 3.75L14.25 1.5L16.5 3.75L14.25 6M12 3.75L14.25 6" stroke={props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

IcEdit1.defaultProps = {
  size: 18,
  color: 'white'
}

export default IcEdit1;
