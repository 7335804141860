import axios from "axios";
import { API_BACKEND_ENDPOINT } from "../config";

const api = axios.create({
  baseURL: API_BACKEND_ENDPOINT, // Replace with your API base URL
});
api.defaults.headers.common['X-Timezone-Offset'] = new Date().getTimezoneOffset().toString();
api.interceptors.request.use((config) => {
  // Get the current URL with path
  const origin = encodeURIComponent(`${window.location.origin}${window.location.pathname}`);

  // Attach `originalPath` as a query parameter
  config.params = {
    ...config.params,  // Keep any existing params
    origin,      // Add `originalPath` to params
  };

  return config;
}, (error) => {
  return Promise.reject(error);
});

export default api;
