import './styles.css';

export * from './lib/card-cb-price-v2-1/card-cb-price-v2-1';

export * from './lib/tip-card/tip-card';

export * from './lib/card-cb-gift-price/card-cb-gift-price';

export * from './lib/plaque-notifier/plaque-notifier';

export * from './lib/level-information/level-information';

export * from './lib/badge-coming-soon/badge-coming-soon';

export * from './lib/accordion/accordion';

export * from './lib/clc-spinner/clc-spinner';

export * from './lib/avatar/avatar';

export * from './lib/card-cb-price/card-cb-price';

export * from './lib/video-item/video-item';

export * from './lib/bread-crumbs/bread-crumbs';

export * from './lib/author/author';

export * from './lib/card-event/card-event';

export * from './lib/tags/tags';

export * from './lib/ribbon/ribbon';

export * from './lib/input-icon/input-icon';

export * from './lib/progressbar/progressbar';

export * from './lib/poker-player/poker-player';

export * from './lib/divider/divider';

export * from './lib/signup-email-form/signup-email-form';

export * from './lib/login-social/login-social';

export * from './lib/card-course/card-course';

export * from './lib/search-box/search-box';

export * from './lib/card-lesson-1/card-lesson-1';

export * from './lib/tabs/tabs';

export * from './lib/marquee-text/marquee-text';

export * from './lib/leaderboard-group/leaderboard-group';

export * from './lib/leaderboard-item/leaderboard-item';

export * from './lib/pick-path-topic-card/pick-path-topic-card';

export * from './lib/card-performance/card-performance';

export * from './lib/email-subscriber/email-subscriber';

export * from './lib/social-links/social-links';

export * from './lib/progress-chart/progress-chart';

export * from './lib/card-article/card-article';

export * from './lib/card-video/card-video';

export * from './lib/card-category/card-category';

export * from './lib/switch/switch';

export * from './lib/button/button';

export * from './lib/badge/badge';

export * from './lib/calendar-practices/calendar-practices';

export * from './lib/login-email-form/login-email-form';

export * from './lib/left-side-panel/left-side-panel';

export * from './lib/clc-logo/clc-logo';

export * from './lib/card/card';

export * from './lib/topbar/topbar';

const font = new FontFace("Krona One", "url('https://fonts.googleapis.com/css2?family=Krona+One&display=swap')");
font.load().then(() => {
  // document.fonts.add(font);
  // document.body.style.fontFamily = "Krona One, sans-serif";
}).catch(() => {
  console.error("Failed to load Krona One, using fallback.");
  // document.body.style.fontFamily = "Arial, sans-serif";
});
