import styles from './ic-double-right-1.module.scss';

/* eslint-disable-next-line */
export interface IcDoubleRight1Props {
  size: number;
  color: string;
}

export function IcDoubleRight1(props: IcDoubleRight1Props) {
  return (
    <svg className={styles['container']} width={props.size} height={props.size / 30 * 31} viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.0664 15.8066C24.6243 15.4523 24.6243 14.6381 24.0664 14.2838L16.1116 9.23283C15.5111 8.85155 14.7262 9.28293 14.7262 9.99422L14.7262 20.0962C14.7262 20.8074 15.5111 21.2388 16.1116 20.8576L24.0664 15.8066Z" fill={props.color} />
      <path d="M17.3747 15.8066C17.9327 15.4523 17.9327 14.6381 17.3747 14.2838L9.41992 9.23283C8.81946 8.85155 8.03455 9.28293 8.03455 9.99422L8.03455 20.0962C8.03455 20.8074 8.81945 21.2388 9.41992 20.8576L17.3747 15.8066Z" fill={props.color} />
    </svg>
  );
}

IcDoubleRight1.defaultProps = {
  size: 30,
  color: 'white'
}

export default IcDoubleRight1;
