import { calcUntilAllowed, ISubscriptionWithSubscriptionItems } from '@clc-v2/utilities';
import { CBItemPrice, CBPaymentSource } from '@prisma/client';
import { createSlice } from '@reduxjs/toolkit'

export interface PaymentState {
    item_prices?: CBItemPrice[];
    giftable_item_prices?: CBItemPrice[];
    cb_instance?: any;
    subscription?: ISubscriptionWithSubscriptionItems;
    until_allowed?: number;
    payment_source?: CBPaymentSource;
}

// export function isPaymentState(obj: any): obj is PaymentState {
//     return typeof obj === 'object' &&
//         obj.hasOwnProperty('item_prices') &&
//         obj.hasOwnProperty('cb_instance') &&
//         obj.hasOwnProperty('subscription') &&
//         obj.hasOwnProperty('until_allowed') &&
//         obj.hasOwnProperty('payment_source');
// }

const defaultState: PaymentState = {
    item_prices: undefined,
    giftable_item_prices: undefined,
    cb_instance: undefined,
    subscription: undefined,
    until_allowed: undefined,
    payment_source: undefined,
}

export const paymentSlice = createSlice({
    name: 'payment',
    initialState: defaultState,
    reducers: {
        set_item_prices: (state: PaymentState, action: { payload: CBItemPrice[] }) => {
            state.item_prices = action.payload;
        },
        set_giftable_item_prices: (state: PaymentState, action: { payload: CBItemPrice[] }) => {
            state.giftable_item_prices = action.payload;
        },
        set_cb_instance: (state: PaymentState, action: { payload: any }) => {
            state.cb_instance = action.payload;
        },
        set_subscription: (state: PaymentState, action: { payload: ISubscriptionWithSubscriptionItems }) => {
            state.subscription = action.payload;
            // state.until_allowed = action.payload.current_term_end ?? 0;
            state.until_allowed = calcUntilAllowed(action.payload);
        },
        set_payment_source: (state: PaymentState, action: { payload: CBPaymentSource }) => {
            state.payment_source = action.payload;
        },
        clear_payment_data: (state: PaymentState) => {
            state = defaultState;
        },
    },
})

export const {
    set_item_prices,
    set_giftable_item_prices,
    set_cb_instance,
    set_subscription,
    set_payment_source,
    clear_payment_data,
} = paymentSlice.actions
export const paymentReducer = paymentSlice.reducer