import classNames from 'classnames';
import styles from './tip-card.module.scss';

/* eslint-disable-next-line */
export interface TipCardProps {
  title: string;
  children?: React.ReactNode;
}

export function TipCard(props: TipCardProps) {
  return (
    <div className={classNames(
      styles['container'],
      "w-full px-8 py-4 font-inter",
      "backdrop-blur-sm",
      "rounded-large"
    )}>
      <div className="w-full h-12 border-b border-black-grey">
        <h4 className="text-white-grey text-xl font-inter">{props.title}</h4>
      </div>
      {props.children}
    </div>
  );
}

export default TipCard;
