import classNames from 'classnames';
import styles from './button.module.scss';
import { ReactNode } from 'react';

/* eslint-disable-next-line */
export interface ButtonProps {
  type: 'primary' | 'secondary' | 'golden',
  children: ReactNode,
  className?: string,
  onClick?: () => void,
}

export function Button(props: ButtonProps) {
  return (
    <div className={classNames(
      styles['container'],
      'w-fit h-10',
      'rounded-full',
      'flex justify-center items-center',
      'cursor-pointer',
      {
        'border border-solid border-primary-golden': props.type === 'primary',
        'border-none': props.type === 'secondary' || props.type === 'golden',
      },
      {
        'bg-transparent': props.type === 'primary',
        'bg-primary-dark-grey hover:bg-secondary-coal': props.type === 'secondary',
        'bg-primary-golden': props.type === 'golden'
      },
      {
        'shadow shadow-primary-golden': props.type === 'golden'
      },
      {
        'px-1': props.type === 'primary'
      },
      props.className
    )} onClick={props.onClick}>
      <div className={classNames(
        'w-full',
        'h-8 px-4 flex justify-center items-center rounded-full',
        'transition-all duration-200',
        {
          'bg-transparent hover:bg-primary-golden': props.type === 'primary'
        },
        {
          'text-white hover:text-black': props.type === 'primary',
          'text-white': props.type === 'secondary'
        },
      )}>
        <span className='text-xs leading-[14px] trakcing-[2px] font-inter'>{props.children}</span>
      </div>
    </div>
  );
}

export default Button;
