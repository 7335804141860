import { AxiosError, AxiosInstance } from "axios";
import { Level, LibraryVideoWatched, RewardPoints } from "@prisma/client";
import api from "./_api";

class PointSystemService {
  private api: AxiosInstance | undefined
  constructor(_api?: AxiosInstance) {
    this.api = _api;
  }
  async getLevels() {
    return new Promise<Level[]>((resolve, reject) => {
      this.api?.get(`/point-system/levels`).then((res: { data: Level[] }) => {
        resolve(res.data);
      }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err.message);
        }
      });
    });
  }
  async claimLibraryVideoWatchOnce(library_video_id: string) {
    return new Promise<LibraryVideoWatched>((resolve, reject) => {
      this.api?.post(`/point-system/claim-library-video-watch-once`, {
        library_video_id
      }).then((res: { data: LibraryVideoWatched }) => {
        resolve(res.data)
      }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err.message);
        }
      });
    })
  }
  async claimDaily20QuestionsReward() {
    return new Promise<{
      last_claimed_daily_20_questions: string,
      user_last_claimed_daily_20_questions: string
    }>((resolve, reject) => {
      this.api?.post(`/point-system/claim-daily-20-questions`).then((res: {
        data: {
          last_claimed_daily_20_questions: string,
          user_last_claimed_daily_20_questions: string
        }
      }) => {
        resolve(res.data);
      }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err.message);
        }
      });
    });
  }
  async claimWeeklyNewHandsReward() {
    return new Promise<{
      last_claimed_weekly_new_hands: string;
      user_last_claimed_weekly_new_hands: string;
    }>((resolve, reject) => {
      this.api?.post(`/point-system/claim-weekly-new-hands`).then((res: {
        data: {
          last_claimed_weekly_new_hands: string;
          user_last_claimed_weekly_new_hands: string;
        }
      }) => {
        resolve(res.data);
      }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err.message);
        }
      });
    });
  }
  async getRewardPoints() {
    return new Promise<RewardPoints[]>((resolve, reject) => {
      this.api?.get(`/point-system/reward-point`).then((res: { data: RewardPoints[] }) => {
        resolve(res.data);
      }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err.message);
        }
      });
    });
  }
}

export const pointSystemService = new PointSystemService(api);
