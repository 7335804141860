import styles from './ic-gift.module.scss';

/* eslint-disable-next-line */
export interface IcGiftProps {
  size: number;
  color: string;
}

export function IcGift(props: IcGiftProps) {
  return (
    <svg className={styles['container']} width={props.size} height={props.size * 26 / 25} viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.5 6.22917V8.83333M12.5 6.22917C12.5 4.79093 13.6659 3.625 15.1042 3.625C16.5424 3.625 17.7083 4.79093 17.7083 6.22917C17.7083 7.66741 16.5424 8.83333 15.1042 8.83333M12.5 6.22917C12.5 4.79093 11.3341 3.625 9.89583 3.625C8.45759 3.625 7.29167 4.79093 7.29167 6.22917C7.29167 7.66741 8.45759 8.83333 9.89583 8.83333M12.5 8.83333H15.1042M12.5 8.83333H9.89583M12.5 8.83333V15.0833M15.1042 8.83333H18.5419C19.7086 8.83333 20.2912 8.83333 20.7369 9.0604C21.1289 9.26014 21.4484 9.57862 21.6482 9.97062C21.875 10.4158 21.875 10.999 21.875 12.1634V15.0833M9.89583 8.83333H6.45854C5.29176 8.83333 4.70794 8.83333 4.26229 9.0604C3.87028 9.26014 3.55181 9.57862 3.35207 9.97062C3.125 10.4163 3.125 11.0001 3.125 12.1669V15.0833M3.125 15.0833V18.0002C3.125 19.167 3.125 19.7501 3.35207 20.1957C3.55181 20.5877 3.87028 20.9068 4.26229 21.1065C4.7075 21.3333 5.29062 21.3333 6.45511 21.3333H12.5M3.125 15.0833H12.5M12.5 15.0833V21.3333M12.5 15.0833H21.875M12.5 21.3333H18.5449C19.7094 21.3333 20.2917 21.3333 20.7369 21.1065C21.1289 20.9068 21.4484 20.5877 21.6482 20.1957C21.875 19.7505 21.875 19.1682 21.875 18.0037V15.0833" stroke={props.color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

IcGift.defaultProps = {
  size: 25,
  color: 'white'
}

export default IcGift;
