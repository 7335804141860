import { CLCAvatars, LatestArticles, PrizeSliceText } from "@prisma/client";
import { AxiosError, AxiosInstance } from "axios";
import { UTMConfigs } from "@clc-v2/utilities";
import api from "./_api";

class DataService {
    private api: AxiosInstance | undefined
    constructor(_api?: AxiosInstance) {
        this.api = _api;
    }
    getPrizeSliceTexts() {
        return new Promise<Array<PrizeSliceText>>((resolve, reject) => {
            this.api?.get(`/prize-texts`).then((res: { data: PrizeSliceText[] }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            })
        })
    }
    getLatestArticles() {
        return new Promise<Array<LatestArticles>>((resolve, reject) => {
            this.api?.get(`/latest-articles`).then((res: { data: LatestArticles[] }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            })
        })
    }
    getCurrentServerTime() {
        return new Promise<string>((resolve, reject) => {
            this.api?.get(`/current-time`).then((res: { data: string }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            })
        })
    }
    getCountDailyQuestions() {
        return new Promise<number>((resolve, reject) => {
            this.api?.get(`/server-vars/count-daily-questions`).then((res: { data: { count: number } }) => {
                resolve(res.data.count)
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            })
        })
    }
    getCLCAvatars() {
        return new Promise<CLCAvatars[]>((resolve, reject) => {
            this.api?.get(`/image-processor/clc-avatars`).then((res: { data: CLCAvatars[] }) => {
                resolve(res.data)
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            })
        })
    }
    updateCountTutorialVideo(user_id: string) {
        return new Promise<number>((resolve, reject) => {
            this.api?.post(`/server-vars/count-tutorial-video`, {
                user_id,
            }).then((res: { data: { count: number } }) => {
                resolve(res.data.count)
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            })
        })
    }
    updateCountDiscordTutorialVideo(user_id: string) {
        return new Promise<number>((resolve, reject) => {
            this.api?.post(`/server-vars/count-discord-tutorial-video`, {
                user_id,
            }).then((res: { data: { count: number } }) => {
                resolve(res.data.count)
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            })
        })
    }
    async getUtmConfigs() {
        return new Promise<UTMConfigs>((resolve, reject) => {
            this.api?.get(`/server-vars/utm-configs`).then((res: { data: UTMConfigs }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        });
    }
}

export const dataService = new DataService(api);