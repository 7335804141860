import styles from './ic-close-3.module.scss';

/* eslint-disable-next-line */
export interface IcClose3Props {
  size: number;
  color: string;
}

export function IcClose3(props: IcClose3Props) {
  return (
    <svg className={styles['container']} width={props.size} height={props.size} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 15L19.9999 19.9999M19.9999 19.9999L24.9998 24.9998M19.9999 19.9999L15 24.9998M19.9999 19.9999L24.9998 15M20 35C11.7157 35 5 28.2843 5 20C5 11.7157 11.7157 5 20 5C28.2843 5 35 11.7157 35 20C35 28.2843 28.2843 35 20 35Z" stroke={props.color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

IcClose3.defaultProps = {
  size: 40,
  color: 'white'
}

export default IcClose3;
