import { CBSubscription } from "@prisma/client";

export const calcUntilAllowed = (subscription: CBSubscription) => {
  let until_allowed: number;
  if (subscription.status === 'cancelled') {
    until_allowed = subscription.canceled_at ?? subscription.next_billing_at;
  } else if (subscription.status === 'non_renewing') {
    until_allowed = subscription.current_term_end ?? subscription.next_billing_at;
  } else {
    until_allowed = subscription.next_billing_at;
  }
  return until_allowed;
}
