import classNames from 'classnames';
import styles from './card-cb-price-v2-1.module.scss';
import Button from '../button/button';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { expired as expired_check } from '@clc-v2/utilities';
import { debounce } from 'lodash';

/* eslint-disable-next-line */
export interface CardCbPriceV21Props {
  item_price_id: string;
  price: number;
  currency_code: string;
  name: string;
  description: string;
  selected_or_upgrade?: boolean;
  scheduled: boolean;
  status?: string;
  trial_end?: number | null;
  next_billing_at?: number | null;
  current_term_end?: number | null;
  canceled_at?: number | null;
  cancel_schedule_created_at?: number | null;
  self_description: string;
  monthly_price: number;
  monthly_price_full: number;
  saved_money_text: string;
  bill_text: string;
  tip: string;
  className?: string;
  force_new_purchase?: boolean;
  on_select?: (item_price_id: string, changing_item_price: boolean) => void;
  on_manage?: () => void;
  on_reactivate?: () => Promise<number>;
}

export function CardCbPriceV21(props: CardCbPriceV21Props) {
  const [expired, set_expired] = useState<boolean>(false);
  const [loading, set_loading] = useState<boolean>(false);
  const [show_refresh, set_show_refresh] = useState<boolean>(false);
  const reactivate_handler = debounce(() => {
    if (loading) return;
    set_loading(true);
    props.on_reactivate?.().then((_next_billing_at) => {
      set_show_refresh(true);
    }).catch((err_msg: string) => {
      alert(err_msg);
    }).finally(() => {
      set_loading(false);
    });
  }, 300);
  useEffect(() => {
    const current_term_end = props.current_term_end;
    const next_billing_at = props.next_billing_at;
    if (current_term_end) {
      set_expired(expired_check(current_term_end, 12 * 60));
    } else if (next_billing_at) {
      set_expired(expired_check(next_billing_at, 12 * 60));
    }
  }, [props.current_term_end, props.next_billing_at]);
  return (
    <div className={classNames(
      styles['container'],
      {
        'gradient-account-panel': !props.selected_or_upgrade,
        'bg-primary-golden': props.selected_or_upgrade
      },
      "hover:border-primary-golden",
      "flex flex-col justify-center items-start",
      "w-full h-[465px]",
      "relative",
      'rounded-2xl overflow-hidden',
      'px-6 pb-6 pt-14',
      'group',
      'z-30',
      props.className,
    )}>
      {
        props.tip && (
          <div className={classNames(
            'absolute flex justify-center items-center font-inter font-sm',
            {
              'left-1/2 top-2 transform -translate-x-1/2 bg-[rgba(29,112,0,1)] rounded-full px-2 h-8': props.tip.toLowerCase().includes('best'),
              'left-0 top-0 w-full h-12 bg-black bg-opacity-10': !props.tip.toLowerCase().includes('best'),
              'text-white-grey font-medium': !props.selected_or_upgrade,
              'text-[rgba(52,39,19,1)] font-semibold': props.selected_or_upgrade,
            },
            'uppercase'
          )}>{props.tip}</div>
        )
      }
      <div className='grow-0 shrink-0 h-[268px] border-b border-secondary-grey'>
        <div className='h-28 grow-0 shrink-0'>
          <h2 className={classNames(
            'w-full font-semibold font-inter text-center',
            {
              'text-2xl text-white-grey': !props.selected_or_upgrade,
              'text-3xl text-black-dark-2': props.selected_or_upgrade
            }
          )}>{props.name.replace('USD ', '')}</h2>
          <p className={classNames(
            'w-full font-light font-inter text-center',
            {
              'text-secondary-grey text-xs': !props.selected_or_upgrade,
              'text-black-dark-2 text-sm': props.selected_or_upgrade
            }
          )}>{props.self_description}</p>
        </div>
        <div className='w-full grow shrink my-6 flex flex-col justify-center items-center gap-2'>
          <div className='w-full flex justify-center items-center'>
            {props.monthly_price_full ? (
              <div className='text-fourth-grey font-inter font-normal text-3xl shadow-md line-through mr-2'>${props.monthly_price_full}</div>
            ) : null}
            {props.monthly_price ? (
              <div className={classNames(
                'font-inter',
                {
                  'text-black-dark-2': props.selected_or_upgrade,
                  'text-white-grey': !props.selected_or_upgrade,
                }
              )}>
                <span className='font-semibold text-5xl'>$</span>
                <span className='font-semibold text-5xl'>{Math.floor(props.monthly_price)}</span>
                {props.monthly_price - Math.floor(props.monthly_price) > 0 && (
                  <span className='text-base font-light'>.{Math.round((props.monthly_price - Math.floor(props.monthly_price)) * 100)}</span>
                )}
                <span className='text-base font-light'>/mo</span>
              </div>
            ) : (
              <div className='text-white font-inter text-5xl shadow-md'>
                <span className='font-semibold'>Free</span>
              </div>
            )}
          </div>
          {props.saved_money_text && <div className='w-fit h-8 px-2 flex justify-center items-center bg-[rgba(29,112,0,1)] text-white-grey font-medium font-inter text-sm uppercase rounded-full'>{props.saved_money_text}</div>}
          <p className={classNames(
            'text-sm font-inter font-light text-center',
            {
              'text-secondary-grey': !props.selected_or_upgrade,
              'text-[rgba(77,61,38,1)]': props.selected_or_upgrade
            }
          )}>{props.bill_text}</p>
        </div>
      </div>
      <div className='w-full h-32 flex flex-col justify-end items-center'>
        {props.selected_or_upgrade ? (
          <span className='text-sm font-light font-inter text-[rgba(77,61,38,1)]'>Current Plan</span>
        ) : (
          <>
            <div className='w-full'>
              {
                props.status === 'cancelled' ? (
                  <>
                    <Button className='w-full' type="primary" onClick={reactivate_handler}>
                      {loading ? "Reactivating" : "Reactivate"}
                    </Button>
                    {show_refresh ? <div className='w-full text-center text-gray-400 cursor-pointer' onClick={() => window.location.reload()}>Refresh</div> : null}
                  </>
                ) : (
                  (expired && !props.force_new_purchase) ? (
                    <Button className='w-full' type='primary' onClick={() => props.status === "cancelled" ? undefined : props.on_manage?.()} >Change Plan</Button>
                  ) : !props.selected_or_upgrade ? (
                    <Button type="golden" className='w-full py-4 bg-new-button text-black font-inter font-bold text-base' onClick={() => props.status !== "cancelled" && props.on_select?.(props.item_price_id, false)} >Start Free Trial</Button>
                  ) : (
                    props.selected_or_upgrade ? (
                      <Button className='w-full' type="secondary">
                        Picked
                      </Button>
                    ) : (
                      <Button className='w-full' type="primary" onClick={() => props.on_select?.(props.item_price_id, true)}>
                        {props.scheduled ? "Scheduled" : "Update"}
                      </Button>
                    )
                  )
                )
              }
            </div>
            <p className={classNames(
              'w-full text-center text-secondary-grey text-sm font-light font-inter mt-4',
            )}>Access all features. Cancel anytime.</p>
          </>
        )}
      </div>
    </div >
  );
}

export default CardCbPriceV21;
