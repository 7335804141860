import styles from './topbar.module.scss';
import classNames from 'classnames';
import { IcClose, IcClose1, IcClose2, IcClose3, IcDiscord, IcMenu, IcRing, IcUserGroup } from '@clc-v2/icons';
import ClcLogo from '../clc-logo/clc-logo';
import { AdminNotification, User } from '@prisma/client';
import { useNavigate } from 'react-router-dom';
// import Avatar from '../avatar/avatar';
import Avatar from 'react-avatar';
import { Tooltip } from 'react-tooltip';
import PlaqueNotifier from '../plaque-notifier/plaque-notifier';
import { useEffect, useState } from 'react';

/* eslint-disable-next-line */
export interface TopbarProps {
  count_of_unread_notifications?: number;
  notificationOpenHandler?: () => void;
  user?: User;
  hideMenu?: boolean;
  hideUser?: boolean;
  centerLogo?: boolean;
  current_time?: number;
  opened: boolean;
  plaque_available?: boolean;
  openHandler: () => void;
  logoClickHandler?: () => void;
  checkPlaquesHandler?: () => void;
  joinDiscordHandler?: () => void;
  referFriendHandler?: () => void;
}

export function Topbar(props: TopbarProps) {
  const navigate = useNavigate();
  // const [current_time, set_current_time] = useState<number>(0);
  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     set_current_time((_prev) => _prev + 1);
  //   }, 1000);
  //   return () => {
  //     clearInterval(timer)
  //   }
  // }, [props.current_time]);
  return (
    <div className={classNames(
      styles['container'],
      'w-full',
      'h-16',
      'rounded-full',
      'bg-secondary-transparent-coal',
      'border-color-2 border-solid border-1 md:border-2 xl:border-3',
      'flex justify-between items-center',
      'relative',
      'backdrop-blur'
    )}>
      <div
        className={`${props.hideMenu ? 'hidden' : 'cursor-pointer'} m-4 w-9 h-9 grow-0 shrink-0 flex justify-center items-center border-secondary-coal border-solid border-2 rounded-full ${props.opened ? 'bg-primary-golden' : ''}`}
        onClick={() => {
          if (props.hideMenu) return;
          props.openHandler();
        }}
      >
        {props.opened ? <IcClose /> : <IcMenu />}
      </div>
      <div
        className={classNames(
          'md:absolute md:left-1/2 md:top-1/2 md:transform md:-translate-x-1/2 md:-translate-y-1/2',
          {
            'container flex items-center': props.centerLogo,
            'cursor-pointer': props.logoClickHandler
          }
        )}
        onClick={props.logoClickHandler}
      >
        <ClcLogo className='w-24 h-10 md:w-36 md:h-[60px]' />
      </div>
      {props.user ? (
        <div className='flex items-center'>
          {!props.hideUser && (
            <div className='flex justify-center items-center gap-1 md:gap-4 mr-1 md:mr-4'>
              <div className='flex flex-col justify-center items-center gap-2 cursor-pointer' onClick={props.joinDiscordHandler} title="Join the community here!">
                <IcDiscord size={36} />
              </div>
              <div className='flex flex-col justify-center items-center gap-2 cursor-pointer' onClick={props.referFriendHandler} title="Refer friends here!">
                <IcUserGroup size={36} color='rgba(232, 186, 115, 1)' />
              </div>
            </div>
          )}
          {!props.hideUser && props.count_of_unread_notifications !== undefined && (
            <div className='relative w-9 h-9 flex justify-center items-center border-2 border-secondary-coal rounded-full cursor-pointer' onClick={props.notificationOpenHandler}>
              {props.count_of_unread_notifications > 0 && (
                <div className='absolute right-0 top-0 w-4 h-4 flex justify-center items-center text-sm bg-red-500 rounded-full text-white'>{props.count_of_unread_notifications}</div>
              )}
              <IcRing />
            </div>
          )}
          <div
            className={classNames(
              'm-1 md:m-4 items-center gap-[10px] cursor-pointer',
              {
                'hidden': props.hideUser,
                'flex': !props.hideUser,
              }
            )}
            onClick={() => navigate('/account-v2-1')}
          >
            <div className='hidden lg:flex flex-col items-end text-white text-xs font-normal font-krona-one'>
              <span className='text-lg capitalize'>{props.user.user_name}</span>
              {/* <span>{props.user.given_name}</span>
              <span>{props.user.family_name}</span> */}
            </div>
            <div className={classNames(
              'w-9 h-9 relative',
              {
                'rounded-full border-primary-golden border-solid border-2 overflow-hidden': false
              }

            )}>
              {/* <Avatar picture={props.user.picture} /> */}
              <Avatar size={"36px"} textSizeRatio={1} name={`${props.user.given_name} ${props.user.family_name}`} src={props.user.thumbnail_picture === '' ? props.user.picture : props.user.thumbnail_picture} round />
              {props.plaque_available && (
                <PlaqueNotifier className="absolute -right-2 -top-2" onClick={props.checkPlaquesHandler} />
              )}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Topbar;
