import styles from './ic-calendar-check.module.scss';

/* eslint-disable-next-line */
export interface IcCalendarCheckProps {
  size: number;
  color: string;
}

export function IcCalendarCheck(props: IcCalendarCheckProps) {
  return (
    <svg className={styles['container']} width={props.size} height={props.size * 26 / 24} viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 8.5H19M3 8.5V17.3002C3 18.4203 3 18.9801 3.21799 19.4079C3.40973 19.7842 3.71547 20.0905 4.0918 20.2822C4.5192 20.5 5.07899 20.5 6.19691 20.5H8.5M3 8.5V7.7002C3 6.58009 3 6.01962 3.21799 5.5918C3.40973 5.21547 3.71547 4.90973 4.0918 4.71799C4.51962 4.5 5.08009 4.5 6.2002 4.5H7M19 8.5V7.69691C19 6.57899 19 6.0192 18.7822 5.5918C18.5905 5.21547 18.2837 4.90973 17.9074 4.71799C17.4796 4.5 16.9203 4.5 15.8002 4.5H15M19 8.5V11.5M7 4.5H15M7 4.5V2.5M15 4.5V2.5" stroke={props.color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11 18.5C11 21.2614 13.2386 23.5 16 23.5C18.7614 23.5 21 21.2614 21 18.5C21 15.7386 18.7614 13.5 16 13.5C13.2386 13.5 11 15.7386 11 18.5Z" stroke={props.color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14 18L15 19.5L18 17.5" stroke={props.color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

IcCalendarCheck.defaultProps = {
  size: 24,
  color: 'white',
}

export default IcCalendarCheck;
