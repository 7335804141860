import { Lesson, RewardPoints } from "@prisma/client";
import { API_BACKEND_ENDPOINT } from "../config";

export class SSEService {
  private masterLessonEventSource: EventSource;
  private earnDailyRewardEventSource: EventSource;
  // private aiOpenedLessonEventSource: EventSource;
  private extraRewardEventSource: EventSource;
  private newLevelEventSource: EventSource;
  private plaqueEventSource: EventSource;

  constructor(user_id: string) {
    this.masterLessonEventSource = new EventSource(`${API_BACKEND_ENDPOINT}/topic-lesson-question/lesson/sse-master-lesson/${user_id}`);
    this.earnDailyRewardEventSource = new EventSource(`${API_BACKEND_ENDPOINT}/point-system/sse-earn-daily-reward/${user_id}`);
    // this.aiOpenedLessonEventSource = new EventSource(`${API_BACKEND_ENDPOINT}/topic-lesson-question/lesson/sse-ai-opened-lesson/${user_id}`);
    this.extraRewardEventSource = new EventSource(`${API_BACKEND_ENDPOINT}/point-system/sse-extra-reward/${user_id}`);
    this.newLevelEventSource = new EventSource(`${API_BACKEND_ENDPOINT}/point-system/sse-level/${user_id}`);
    this.plaqueEventSource = new EventSource(`${API_BACKEND_ENDPOINT}/sse/sse-earn-plaque/${user_id}`);
  }

  subscribeToMasterLessonEvent(callback: (_lesson: Lesson) => void) {
    this.masterLessonEventSource.addEventListener('message', (event) => {
      const mastered_lesson: Lesson = JSON.parse(event.data);
      callback(mastered_lesson);
    })
  }

  subscribeToEarnDailyRewardEvent(callback: (_reward: RewardPoints) => void) {
    this.earnDailyRewardEventSource.addEventListener('message', (event) => {
      const reward: RewardPoints = JSON.parse(event.data);
      callback(reward);
    })
  }

  // subscribeToAiOpenedLessonEvent(callback: (_lesson: Lesson) => void) {
  //   this.aiOpenedLessonEventSource.addEventListener('message', (event) => {
  //     const ai_opened_lesson: Lesson = JSON.parse(event.data);
  //     callback(ai_opened_lesson);
  //   })
  // }

  subscribeToExtraRewardEvent(callback: (message: string) => void) {
    this.extraRewardEventSource.addEventListener('message', (event) => {
      const message = event.data;
      callback(message);
    })
  }

  subscribeToNewLevelEvent(callback: (message: string) => void) {
    this.newLevelEventSource.addEventListener('message', (event) => {
      const message = event.data;
      callback(message);
    })
  }

  subscribeToPlaqueEvent(callback: (message: string) => void) {
    this.plaqueEventSource.addEventListener('message', (event) => {
      const message = event.data;
      callback(message);
    })
  }

  closeConnection() {
    this.masterLessonEventSource.close();
    this.earnDailyRewardEventSource.close();
    // this.aiOpenedLessonEventSource.close();
    this.extraRewardEventSource.close();
    this.plaqueEventSource.close();
  }
}