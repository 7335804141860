import styles from './ic-google.module.scss';

/* eslint-disable-next-line */
export interface IcGoogleProps {
  size: number;
}

export function IcGoogle(props: IcGoogleProps) {
  return (
    <svg className={styles['container']} width={props.size} height={props.size} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M39.9771 18.4094H38.5003V18.3333H22.0003V25.6666H32.3614C30.8498 29.9355 26.7881 33 22.0003 33C15.9256 33 11.0003 28.0747 11.0003 22C11.0003 15.9252 15.9256 11 22.0003 11C24.8044 11 27.3555 12.0578 29.2979 13.7857L34.4835 8.60013C31.2092 5.54854 26.8293 3.66663 22.0003 3.66663C11.8757 3.66663 3.66699 11.8754 3.66699 22C3.66699 32.1245 11.8757 40.3333 22.0003 40.3333C32.1249 40.3333 40.3337 32.1245 40.3337 22C40.3337 20.7707 40.2072 19.5708 39.9771 18.4094Z" fill="#FBC02D" />
      <path d="M5.78027 13.4667L11.8037 17.8841C13.4335 13.849 17.3807 11 21.9998 11C24.8039 11 27.3549 12.0578 29.2974 13.7857L34.4829 8.60013C31.2086 5.54854 26.8288 3.66663 21.9998 3.66663C14.9579 3.66663 8.85111 7.64221 5.78027 13.4667Z" fill="#E53935" />
      <path d="M22 40.3332C26.7355 40.3332 31.0383 38.521 34.2915 35.5739L28.6174 30.7724C26.7767 32.1666 24.4887 32.9999 22 32.9999C17.2315 32.9999 13.1825 29.9593 11.6572 25.7161L5.67871 30.3223C8.71288 36.2596 14.8747 40.3332 22 40.3332Z" fill="#4CAF50" />
      <path d="M39.9767 18.4093L39.9621 18.3333H38.5H22V25.6666H32.3611C31.6351 27.7172 30.316 29.4854 28.6147 30.7733L28.6174 30.7715L34.2916 35.573C33.8901 35.9378 40.3333 31.1666 40.3333 21.9999C40.3333 20.7707 40.2068 19.5708 39.9767 18.4093Z" fill="#1565C0" />
    </svg>
  );
}

IcGoogle.defaultProps = {
  size: 24,
}

export default IcGoogle;
